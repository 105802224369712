@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) and (display:flow-root) {
        .selector { 
            position: fixed;
            top: 80%;
        }
    } 
}
@media not all and (min-resolution: .001dpcm){
    @supports(e:none) and (stroke-color:transparent) { 
        .menu-dots-safar-webkit-appearanci {
            position: fixed;
            top: 80%;
        }
    }
}