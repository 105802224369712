$--cor-primaria: "#000000";
$--cor-secundaria: "#ffffff";
$--cor-terciaria: "#272727";

$--cor-link-principal: "#CFCFCF";
$--cor-link-secundaria: "#76BB52";

html {
    --atual-font-size-default: 100%;
    --atual-background-color: "#ffffff";
    --atual-font-color__primary: "#000000";
    --atual-font-color__secundary: "#ffffff";
    --atual-menu-link-no-scrolled-color: "#ccc";
    --atual-menu-link-scrolled-color__link: "#000000";
    --atual-acessibility-link-color__link: "#ffffff";
}
