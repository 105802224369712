html.cinzainvertida body {
  background-color: #000 !important;
  filter: invert(100%) grayscale(100%);
  -webkit-filter: invert(100%) grayscale(100%);
}
html.cinzainvertida body a {
  text-decoration: underline;
}
html.cinzainvertida body img:not(.noFilterCI),
html.cinzainvertida body iframe:not(.noFilterCI),
html.cinzainvertida body video:not(.noFilterCI),
html.cinzainvertida body .noFilter {
  filter: invert(100%);
  -webkit-filter: invert(100%);
}
