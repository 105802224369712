html.altocontraste body {
  filter: contrast(1.5);
  -webkit-filter: contrast("1.5");
}
html.altocontraste body img,
html.altocontraste body iframe,
html.altocontraste body video {
  filter: contrast(0.65);
  -webkit-filter: contrast(0.65);
}
