@import "santillanaAcessibilidade.css";

.container {
	width: 100%;
}

body .barraAcessivel ul li button {
	display: block;
	padding: 0 7px;
	line-height: 35px;
	font-size: 13px;
	color: #fff;
	background-color: transparent;
	border: none;
  }