@media (min-width: 1200px) {
  .main-container {
    width: 1200px;
    margin: 0 auto;
  }

  .routes-container {
    margin-top: -100px;
  }
}

.routes-container {
  min-height: calc(100vh - 120px - 318px);
}

.m-auto {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1200px) {
  :target {
    scroll-margin-top: 60px;
  }
}

body {
  -webkit-overflow-scrolling: touch;
  // overscroll-behavior-y: none;
}
