/* Novas fonts */
@font-face {
  font-family: "TitilliumWeb";
  src: url("../fonts/TitilliumWeb/TitilliumWeb-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb-Regular";
  src: url("../fonts/TitilliumWeb/TitilliumWeb-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb-Bold";
  src: url("../fonts/TitilliumWeb/TitilliumWeb-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb-Italic";
  src: url("../fonts/TitilliumWeb/TitilliumWeb-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb-Light";
  src: url("../fonts/TitilliumWeb/TitilliumWeb-Light.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb-LightItalic";
  src: url("../fonts/TitilliumWeb/TitilliumWeb-LightItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "TitilliumWeb-Medium";
  src: url("../fonts/TitilliumWeb/TitilliumWeb-SemiBold.ttf") format("truetype");
}
