html.amarelopreto body {
  background: #000000 !important;
  background: #000000;
}
html.amarelopreto body a:not(.noFilterAP) {
  text-decoration: underline;
  color: #ffff00 !important;
  background: #000000 !important;
  border-color: #ffffff !important;
}
html.amarelopreto body a:not(.noFilterAP):before {
  color: #ffff00 !important;
}
html.amarelopreto body a:not(.noFilterAP) span {
  color: #ffff00 !important;
}
html.amarelopreto body a:not(.noFilterAP):link,
html.amarelopreto body a:not(.noFilterAP):visited,
html.amarelopreto body a:not(.noFilterAP):hover {
  text-decoration: underline;
  color: #ffff00 !important;
  background: #000000 !important;
  border-color: #ffffff !important;
}
html.amarelopreto body a:not(.noFilterAP):link:before,
html.amarelopreto body a:not(.noFilterAP):visited:before,
html.amarelopreto body a:not(.noFilterAP):hover:before {
  color: #ffff00 !important;
}
html.amarelopreto body a:not(.noFilterAP):link span,
html.amarelopreto body a:not(.noFilterAP):visited span,
html.amarelopreto body a:not(.noFilterAP):hover span {
  color: #ffff00 !important;
}
html.amarelopreto body a:not(.noFilterAP):before {
  border-color: #000000 !important;
}
html.amarelopreto body p:not(.noFilterAP),
html.amarelopreto body b:not(.noFilterAP),
html.amarelopreto body li:not(.noFilterAP),
html.amarelopreto body ul:not(.noFilterAP),
html.amarelopreto body div:not(.noFilterAP),
html.amarelopreto body td:not(.noFilterAP),
html.amarelopreto body tr:not(.noFilterAP),
html.amarelopreto body h1:not(.noFilterAP),
html.amarelopreto body h2:not(.noFilterAP),
html.amarelopreto body h3:not(.noFilterAP),
html.amarelopreto body h4:not(.noFilterAP),
html.amarelopreto body h5:not(.noFilterAP),
html.amarelopreto body h6:not(.noFilterAP),
html.amarelopreto body span:not(.noFilterAP),
html.amarelopreto body label:not(.noFilterAP),
html.amarelopreto body button:not(.noFilterAP),
html.amarelopreto body section:not(.noFilterAP),
html.amarelopreto body header:not(.noFilterAP),
html.amarelopreto body footer:not(.noFilterAP),
html.amarelopreto body article:not(.noFilterAP),
html.amarelopreto body aside:not(.noFilterAP) {
  color: #ffffff !important;
  background: #000000;
  border-color: #ffffff !important;
}
html.amarelopreto body input[type='text'],
html.amarelopreto body select[type='text'] {
  border: 1px solid #ffffff !important;
}
html.amarelopreto body input:not(.noFilterAP),
html.amarelopreto body select:not(.noFilterAP) {
  color: #ffffff !important;
  background: #000000 !important;
  border-color: #ffffff !important;
}
html.amarelopreto body a:link,
html.amarelopreto body a:visited {
  text-decoration: underline;
  color: #ffff00;
}
html.amarelopreto body div:before,
html.amarelopreto body h1:before,
html.amarelopreto body h2:before,
html.amarelopreto body h3:before {
  background: transparent;
}
html.amarelopreto body div:after,
html.amarelopreto body h1:after,
html.amarelopreto body h2:after,
html.amarelopreto body h3:after {
  background: transparent;
}
html.amarelopreto body input[type='radio']:empty ~ label:before {
  background: #000000 !important;
}
html.amarelopreto body input[type='radio']:checked ~ label:before {
  background: #ffffff !important;
}
html.amarelopreto body div:not(.noFilterAP):not([id=""]):not([style^="background-image:url"]),
html.amarelopreto body section:not(.noFilterAP):not([id=""]):not([style^="background-image:url"]),
html.amarelopreto body nav:not(.noFilterAP):not([id=""]):not([style^="background-image:url"]) {
  background: #000000 !important;
}
html.amarelopreto body div:not(.noFilterAP):not([id=""]):not([style^="background-image:url"]) input,
html.amarelopreto body section:not(.noFilterAP):not([id=""]):not([style^="background-image:url"]) input,
html.amarelopreto body nav:not(.noFilterAP):not([id=""]):not([style^="background-image:url"]) input,
html.amarelopreto body div:not(.noFilterAP):not([id=""]):not([style^="background-image:url"]) button,
html.amarelopreto body section:not(.noFilterAP):not([id=""]):not([style^="background-image:url"]) button,
html.amarelopreto body nav:not(.noFilterAP):not([id=""]):not([style^="background-image:url"]) button {
  background: #000000 !important;
}
html.amarelopreto body li:hover:not([style^="background-image:url"]) {
  background: #000000 !important;
}
