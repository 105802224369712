body {
  background: #ffffff;
}
body .barraAcessivel {
  font-family: "Arial", sans-serif;
  background: #eee;
  position: relative;
  z-index: 99;
}
body .barraAcessivel * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body .barraAcessivel *:before,
body .barraAcessivel *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body .barraAcessivel .clearfix {
  clear: both;
}
body .barraAcessivel .pull-left {
  float: left !important;
}
body .barraAcessivel .pull-right {
  float: right !important;
}
body .barraAcessivel .container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
body .barraAcessivel .container .row {
  margin-left: -15px;
  margin-right: -15px;
}
body .barraAcessivel ul {
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}
body .barraAcessivel ul li {
  display: inline-block;
  position: relative;
}
body .barraAcessivel ul li a, 
body .barraAcessivel ul li button {
  display: block;
  padding: 0 7px;
  line-height: 35px;
  font-size: 13px;
  color: #555;
}
body .barraAcessivel ul li a.livroAcessivel {
  padding-left: 20px;
}
body .barraAcessivel ul li a.livroAcessivel:before {
  content: "";
  background-image: url("../images/livro-acessivel.png");
  background-position: center center;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  margin-left: -20px;
}
body .barraAcessivel ul li a.contraste,
body .barraAcessivel ul li button.contraste {
  padding-left: 30px;
}
body .barraAcessivel ul li a.contraste:before,
body .barraAcessivel ul li button.contraste:before {
  content: "";
  background-image: url("../images/contraste.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  margin-left: -20px;
}
@media only screen and (min-width: 992px) {
  body .barraAcessivel ul li a.contraste, 
  body .barraAcessivel ul li button.contraste {
    height: 12px;
  }
  body .barraAcessivel ul li a.contraste:before,
  body .barraAcessivel ul li button.contraste:before {
    top: -5px;
    margin-top: -6px;
    margin-left: -22px;
    padding: 12px 0;
  }
}
body .barraAcessivel ul li a.pgmapadosite {
  padding-left: 30px;
}
@media only screen and (min-width: 1024px){
  body .barraAcessivel ul li a.pgmapadosite {
    height: 12px;
  }
}
body .barraAcessivel ul li a.pgmapadosite:before {
  content: "";
  background-image: url("../images/icon-mapadosite.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 18px;
  height: 16px;
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  margin-left: -24px;
}
@media only screen and (min-width: 1024px) {
  body .barraAcessivel ul li a.pgmapadosite:before {
    content: "";
    background-image: url("../images/icon-mapadosite.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 18px;
    height: 16px;
    display: inline-block;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    margin-left: -24px;
  }
}
body .barraAcessivel ul li a.pglibras {
  position: relative;
  float: right;
  height: 35px;
  width: 44px;
  padding: 0 7px;
}
body .barraAcessivel ul li a.pglibras:before {
  content: "";
  background-image: url("../images/icon-libras.png");
  background-position: center center;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  display: inline-block;
  position: relative;
  line-height: 30px;
  top: 2px;
  right: 7px;
  padding: 0;
  position: absolute;
}
body .barraAcessivel ul li a.pglibras span {
  display: none;
}
body .barraAcessivel ul li a.gm5zoom {
  font-size: 17px;
  font-weight: 700;
  padding: 0 10px;
}
body .barraAcessivel ul li a span.oculto,
body .barraAcessivel ul li button span.oculto {
  position: absolute;
  text-indent: -9999px;
  display: block;
  height: 0;
  width: 0;
}
body .barraAcessivel ul li a:hover,
body .barraAcessivel ul li button:hover {
  text-decoration: underline;
  color: inherit;
}
body .barraAcessivel ul li a:focus,
body .barraAcessivel ul li button:focus {
  outline: 3px solid rgba(85, 85, 85, 0.7);
  text-decoration: none;
  color: #fff;
}
body .barraAcessivel ul li.lilibras {
  float: right;
  height: 35px;
  width: 44px;
}
body .barraAcessivel .dautoniveis {
  position: absolute;
  width: 280px;
  overflow: hidden;
  background: #eee;
  transition: all 300ms ease;
}
body .barraAcessivel .dautoniveis.closed {
  height: 0;
  opacity: 0;
  display: none;
}
body .barraAcessivel .dautoniveis.opened {
  height: auto;
  opacity: 1;
  display: block;
}
@media only screen and (min-width: 1024px) {
  body .barraAcessivel .dautoniveis.opened {
    top: 25px;
  }
}
body .barraAcessivel .dautoniveis ul {
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: block;
}
body .barraAcessivel .dautoniveis ul li {
  display: block;
  overflow: hidden;
}
body .barraAcessivel .dautoniveis ul li a {
  border-top: 1px solid #555; 
  padding: 2px 10px;
  color: #555;
  font-weight: 600;
}
body .barraAcessivel .dautoniveis ul li a:focus,
body .barraAcessivel .dautoniveis ul li button:focus {
  outline: 3px solid rgba(85, 85, 85, 0.7);
  color: #fff;
}
/* @media (min-width: 768px) {
  body .barraAcessivel .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  body .barraAcessivel .container {
    width: 960px;
  }
}
@media (min-width: 992px) {
  body .barraAcessivel .container {
    width: 960px;
  }
} */
@media (max-width: 992px) {
  .barraAcessivel ul li {
    float: left;
  }
  .barraAcessivel ul li a:not(.pglibras, .pgmapadosite, .contraste):before,
  .barraAcessivel ul li button:not(.pglibras, .pgmapadosite, .contraste):before {
    display: none !important;
  }
  .barraAcessivel ul li a.livroAcessivel,
  .barraAcessivel ul li a.pglibras,
  .barraAcessivel ul li a.pgmapadosite,
  .barraAcessivel ul li a.contraste,
  .barraAcessivel ul li button.contraste {
    height: 35px;
    padding-left: 30px !important;
  }
  .barraAcessivel ul li a.livroAcessivel:before,
  .barraAcessivel ul li a.pglibras:before,
  .barraAcessivel ul li a.pgmapadosite:before,
  .barraAcessivel ul li a.contraste:before,
  .barraAcessivel ul li button.contraste:before {
    height: 30px;
  }
  .barraAcessivel ul li a.livroAcessivel span,
  .barraAcessivel ul li a.pglibras span,
  .barraAcessivel ul li a.pgmapadosite span,
  .barraAcessivel ul li a.contraste span,
  .barraAcessivel ul li button.contraste span {
    display: none;
  }
  .barraAcessivel ul li.zoomButtons {
    display: none;
  }
  .barraAcessivel .dautoniveis {
    width: 320px;
    right: -90px;
  }
  .barraAcessivel .dautoniveis li {
    width: 100%;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .barraAcessivel .hidden-sm {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
  .barraAcessivel .dautoniveis {
    width: 280px;
    right: -81px;
  }
}
